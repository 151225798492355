import React from "react"
import { Link } from 'gatsby'
import Logo from "../../../static/images/logo/logo.svg"
import Seo from "../../components/seo"
import BookHeader from "../../components/bookPageHeader"
import BookSlider from "../../components/bookSlider"
import ReactBook from "../../../static/images/commonImg/reactbook.svg"
import ReactBookTS from "../../../static/images/commonImg/reactbook-ts.svg"
import ReactBook2 from "../../../static/images/commonImg/reactbook2.svg"
import ReactBook2TS from "../../../static/images/commonImg/reactbook2-ts.svg"
import NodeExpressBook from "../../../static/images/commonImg/nodebook-intro.svg"
import NodeExpressBook2 from "../../../static/images/commonImg/nodebook-advanced.svg"
import NextBook2a from "../../../static/images/commonImg/nextbook2-1.svg"
import NextBook2aTS from "../../../static/images/commonImg/nextbook2-1ts.svg"
import MernBook from "../../../static/images/commonImg/mern-book-1.svg"
//import GatsbyBook from "../../../static/images/commonImg/gatsbybook.svg"
import NextBook from "../../../static/images/commonImg/nextbook.svg"
import JamstackBook from  "../../../static/images/commonImg/jamstackbook.svg"
import vuebook from "../../../static/images/commonImg/vuebook.svg"
import NuxtBook from "../../../static/images/commonImg/nuxtbook.svg"
import JSBook from "../../../static/images/commonImg/jsbook.svg"
import ProxyBook from "../../../static/images/commonImg/proxyserver-book.png"
import ReactMasterBook from "../../../static/images/commonImg/react-master-book.svg" 
import RegisterNewsLetterBody from "../../components/registerNewsLetter"

const generalData = {
    title: "はじめてつくるGatsbyサイト（Gatsby v5）",
    updated: "2023/4/11",
    interview1: "",
    urlToApp: "https://gatsbybook-portfolio-site.netlify.app",
    urlToCode1: "https://github.com/mod728/gatsbybook-portfolio-site-v5",
    urlToCode2: "",
    reviewLink1: "https://www.amazon.co.jp/review/create-review/?ie=UTF8&channel=glance-detail&asin=B09MZYGMT3",
    reviewLink2: "",
   /*  codePageUrl: "https://monotein-books.netlify.app/gatsbybook", */
}

const booksData = [
    {oldPrice: "", newPrice: "", newRelease: "*2024年2月発売", title: "React全部入り：Reactマスター Zero To Hero", img: ReactMasterBook, url: "https://monotein.base.shop/p/00001", description: "Reactの基礎から発展的内容まで全範囲をカバーした待望の解説書！<br/><br/>ハンズオン（作りながら学ぶ）形式で、「仮想DOM」「コンポーネント指向」などのReactの基礎から、useState／useEffectなどの全Hooksまでカバー。React入門に成功したビギナーが、Reactのたしかな理解とスキルを身につける一冊。"},
    {oldPrice: "", newPrice: "", newRelease: "", title: "はじめてつくるNext.jsサイト", img: NextBook, url: "https://www.amazon.co.jp/dp/B0C7746ZC4", description: "2時間でNext.jsの使い方がわかる入門書。<br/><br/>最新のNext.jsバージョン13と、新たに導入されたAppフォルダの使い方を解説している唯一の日本語書籍（2023年6月時点）"},
    {newRelease: "", title: "Next.jsでつくるフルスタックアプリ（前編・後編）", img: NextBook2a, url: "https://www.amazon.co.jp/dp/B09YWSHR58", description: "「Next.jsをバックエンドとフロントエンド両方に使いたい」<br/><br/>「サーバーサイド・レンダリングをしてみたい」<br/><br/>という人向けの本書続編です。"},
    {newRelease: "", title: "はじめてつくるJamstackサイト with ヘッドレスCMS", img: JamstackBook, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: "「WordPress以外でウェブ制作をしてみたい」<br/><br/>という人向けの入門書。<br/><br/>ヘッドレスCMSと静的サイトジェネレーターを使ったJamstackサイト開発を紹介します。"},
]

const booksDataForSlider = [
    {newRelease: "", title: "", img: ReactBook, url: "https://www.amazon.co.jp/dp/B08XWMVX76", description: ""},
    {newRelease: "", title: "", img: ReactBookTS, url: "https://www.amazon.co.jp/dp/B094Z1R281", description: ""},
    {newRelease: "", title: "", img: ReactBook2, url: "https://www.amazon.co.jp/dp/B09693ZJCV", description: ""},
    {newRelease: "", title: "", img: ReactBook2TS, url: "https://www.amazon.co.jp/dp/B09Q57Q9T1", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook, url: "https://www.amazon.co.jp/dp/B09HQZ1BW8", description: ""},
    {newRelease: "", title: "", img: NodeExpressBook2, url: "https://www.amazon.co.jp/dp/B09X56PSCQ", description: ""},
    {newRelease: "", title: "", img: MernBook, url: "https://www.amazon.co.jp/dp/B0BJN4H1NF", description: ""},
    {newRelease: "", title: "", img: NextBook2aTS, url: "https://www.amazon.co.jp/dp/B0BD552RD6", description: ""},
    {newRelease: "", title: "", img: vuebook, url: "https://www.amazon.co.jp/dp/B0B46QNTNL", description: ""},
    {newRelease: "", title: "", img: JSBook, url: "https://www.amazon.co.jp/dp/B0C9C9SVFG", description: ""},
    {newRelease: "", title: "", img: ProxyBook, url: "https://www.amazon.co.jp/dp/B0BBMQS28M", description: ""},
]

const links = [
    {
        chapterNum: 1,
        data: [
            {name: "Node.js", url: "https://nodejs.org/en/download"},
            {name: "VS Code", url: "https://code.visualstudio.com/download"},
            {name: "エラー発生時の対処方法", url: "https://monotein.com/blog/how-to-find-and-fix-errors"},
        ]
    },
    {
        chapterNum: 3,
        data: [
            {name: "画像のダウンロード", url: "https://github.com/mod728/gatsbybook-portfolio-site-v5/tree/chapter3/src/images"},
            {name: "CSSファイル", url: "https://github.com/mod728/gatsbybook-portfolio-site-v5/tree/chapter3/src/styles"},
            {name: "マークダウンファイル", url: "https://github.com/mod728/gatsbybook-portfolio-site-v5/tree/chapter3/src/data"},
            {name: "第3章完了時のソースコード", url: "https://github.com/mod728/gatsbybook-portfolio-site-v5/tree/chapter3"},
        ]
    },
    {
        chapterNum: 4,
        data: [
            {name: "Netlify", url: "https://www.netlify.com"},
        ]
    },
    {
        chapterNum: 5,
        data: [
            {name: "第5章完了時のソースコード", url: "https://github.com/mod728/gatsbybook-portfolio-site-v5"},
            {name: "アマゾンレビューURL", url: generalData.reviewLink1},
        ]
    },
]

const GatsbyBookLinks = () => (
    <div className="container2">
        <Seo title={generalData.title} noindex={true} />
        <div><Link to="/blog"><img src={Logo} style={{width: "180px", margin: "auto", display: "block", paddingTop: "25px"}}/></Link></div>
        <div style={{textAlign: "center", marginTop: "16px"}}><a rel="noopener noreferrer" target="_blank" href="/blog">フロントエンド情報ブログ</a><span> || </span><a rel="noopener noreferrer" target="_blank" href="/business">著者について</a></div>
        <h3>『{generalData.title}』リンクページ</h3>
        <h4>更新日：<span>{generalData.updated}</span></h4>
        <BookHeader/>
        <ul>
            <h3 style={{backgroundColor: "#525252", display: "inline-block", color: "#fff", padding: "0 40px"}}>全般</h3>
            <li><span style={{color: "red"}}>*NetlifyでのBuildが失敗する場合</span>：<a rel="noopener noreferrer" target="_blank" href="https://monotein-books.netlify.app/gatsby-netlify-node-version">解説ページへ移動する</a></li>
            {generalData.interview1 && <li>本書に関するインタビュー記事：<a rel="noopener noreferrer" target="_blank" href={generalData.interview1}>{generalData.interview1}</a></li>}
            {generalData.urlToApp && <li>見本アプリ：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToApp}>{generalData.urlToApp}</a></li>}
            <li>ソースコードダウンロード：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode1}>{generalData.urlToCode1}</a></li>
            {generalData.urlToCode2 && 
                <li>ソースコードダウンロード（後編）：<a rel="noopener noreferrer" target="_blank" href={generalData.urlToCode2}>{generalData.urlToCode2}</a></li>
            }
            {generalData.codePageUrl &&
                <li>本書のコード一覧：<a rel="noopener noreferrer" target="_blank" href={generalData.codePageUrl}>{generalData.codePageUrl}</a></li>
            }
            <li><span className="amazon-review">Amazonでレビューする</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink1}>{generalData.reviewLink1}</a></li>
            {generalData.reviewLink2 && 
                <li><span className="amazon-review">Amazonでレビューする（後編）</span>：<a rel="noopener noreferrer" target="_blank" href={generalData.reviewLink2}>{generalData.reviewLink2}</a></li>
            }
        </ul>

        <div className="book-wrapper">
            <div className="gridContainer">
                {booksData.map((x, index) => 
                    <div className="imgTextContainer" key={index}>
                        <a rel="noopener noreferrer" target="_blank" href={x.url}>
                            <img src={x.img} alt="cover"/>
                        </a>
                        <div>
                            {x.newRelease && <p className="new-release">{x.newRelease}</p>}
                            <br/>
                            {x.oldPrice && <p className="new-release-price">リリース記念特価（期間限定）<span>{x.oldPrice}円</span> ➡ {x.newPrice}円</p>}
                            <h3>『{x.title}』</h3>
                            {x.url.includes("monotein.base.shop") ? 
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>→もっとくわしく見る</p></a>
                            :  
                                <a rel="noopener noreferrer" target="_blank" href={x.url}><p>Amazonで見る</p></a>
                            }
                            <p dangerouslySetInnerHTML={{ __html: x.description }}/>
                        </div>
                    </div>
                )}
            </div>
            <BookSlider booksDataForSlider={booksDataForSlider}/>
        </div>

        <ul>
            {links.map((x, index) => 
                <div key={index}>
                    <h3>{typeof x.chapterNum === "string" ? x.chapterNum : `第${x.chapterNum}章`}</h3>
                    {x.data.map((y, index) => 
                        <li key={index}>{y.name}： <a rel="noopener noreferrer" target="_blank" href={y.url}>{y.url}</a></li>
                    )}
                </div>
            )}
        </ul>
        <div style={{marginTop: "90px" }}></div>
        <RegisterNewsLetterBody 
                fromWhere="Gatsby-Book"
                backendUrl="https://us-central1-nov182021.cloudfunctions.net/server/news-letter"
                text={<p>React、Next.js、TypeScriptなど最新のウェブ開発のお役立ち情報を、ビギナー向けにかみ砕いて無料配信中。<br/>（*配信はいつでも停止できます）</p>}
        />
    </div>
)

export default GatsbyBookLinks
